import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandlerFn,
  HttpInterceptorFn,
  HttpRequest,
} from '@angular/common/http';
import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, catchError, throwError } from 'rxjs';
import { clearUserData } from 'src/app/common/auth-feature/store/auth.actions';
import { AlertService } from 'src/app/services/alert.service';
import { StorageService } from 'src/app/services/storage.service';

export const errorInterceptor: HttpInterceptorFn = (
  req: HttpRequest<any>,
  next: HttpHandlerFn
): Observable<HttpEvent<any>> => {
  const router = inject(Router);
  const storageService = inject(StorageService);
  const store = inject(Store);
  const alertService = inject(AlertService);
  return next(req).pipe(
    catchError((errorRes: HttpErrorResponse) => {
      if (errorRes.status === 401) {
        store.dispatch(clearUserData());
        storageService.clearStorage();
        setTimeout(() => {
          if (!router.url.startsWith('/login')) router.navigate(['/login']);
        }, 500);
      }
      const title =
        !errorRes.status && !navigator.onLine
          ? 'Network Error'
          : errorRes.status === 401
          ? ''
          : `${errorRes.statusText}`; //(${errorRes.status})
      const message =
        !errorRes.status && !navigator.onLine
          ? 'Please connect to the internet'
          : errorRes?.error?.description || '';

      alertService.alertDanger(title, message);

      return throwError(
        () =>
          new Error(
            errorRes?.error?.description ||
              'A network error occurred. Please try again later.'
          )
      );
    })
  );
};
