export interface IWebSocketMessage {
  action: WS_ACTION;
  sessionId: string;
  webSocketConnectionId?: string;
  data: any;
  reqOriginConnectionId?: string;
}

export enum WS_ACTION {
  'CONNECTION_INIT' = 'CONNECTION_INIT',
  'BULK_WALLET_BALANCE_ENQUIRY' = 'BULK_WALLET_BALANCE_ENQUIRY',
  'ACTIVE_SERVICES_BY_WALLETNAMES' = 'ACTIVE_SERVICES_BY_WALLETNAMES',
  'AGGREGATE_BALANCE' = 'AGGREGATE_BALANCE',
  'ENQUIRY_ERROR' = 'ENQUIRY_ERROR',
  'WALLET_USAGE_BY_WALLETNAMES' = 'WALLET_USAGE_BY_WALLETNAMES',
  'WALLET_USAGE_BY_SVCUSTOMER_IDS' = 'WALLET_USAGE_BY_SVCUSTOMER_IDS',
}

export interface IWsConnectionDetails {
  webSocketConnectionId: string;
  sessionId: string;
  reqOriginConnectionId?: string;
}
