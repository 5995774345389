import { HttpErrorResponse } from '@angular/common/http';
import { inject } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { map, catchError, of, exhaustMap, switchMap, first, EMPTY } from 'rxjs';
import { API } from 'src/app/common/models/api.enum';
import { CommonApiService } from 'src/app/services/common-api.service';

import { IProfileDetailState } from 'src/app/common/models/profiles.model';
import {
  myProfileDetail,
  myProfileDetailFailed,
  myProfileDetailSuccess,
} from './my-profile.actions';
import { Store } from '@ngrx/store';
import { selectMyProfileDetailData } from './my-profile.selectors';

export const myProfileDetailEffect = createEffect(
  (
    actions$ = inject(Actions),
    apiService = inject(CommonApiService),
    store = inject(Store)
  ) => {
    return actions$.pipe(
      ofType(myProfileDetail),
      switchMap((action) =>
        store.select(selectMyProfileDetailData).pipe(
          first(),
          switchMap((data) => (data ? EMPTY : of(action)))
        )
      ),
      exhaustMap((action) =>
        apiService
          .get<IProfileDetailState>(
            API.CST_CC_TEAM_MANAGEMENT_LISTING + 'info/' + action.profileId
          )
          .pipe(
            map((data) => myProfileDetailSuccess({ data })),
            catchError((errorRes: HttpErrorResponse) =>
              of(
                myProfileDetailFailed({
                  error: errorRes?.error || errorRes,
                })
              )
            )
          )
      )
    );
  },
  { functional: true }
);
