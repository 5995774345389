import { createReducer, on } from '@ngrx/store';
import { IWsBbeState } from './ws-bulk-bal-enq.models';
import {
  clearBulkBalanceStore,
  wsFetchBulkBalance,
  wsFetchBulkBalanceSuccess,
} from './ws-bulk-bal-enq.actions';

const initialState: IWsBbeState = {
  data: null,
  isLoading: false,
  error: null,
};

export const wsBulkBalEnqReducer = createReducer(
  initialState,
  on(wsFetchBulkBalance, (state) => ({
    ...state,
    data: null,
    isLoading: true,
    error: null,
  })),
  on(wsFetchBulkBalanceSuccess, (state, { data }) => ({
    ...state,
    data,
    isLoading: false,
    error: null,
  })),
  on(clearBulkBalanceStore, () => ({ ...initialState }))
);
