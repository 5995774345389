import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AlertPayload } from '../app-data/alert.data';

@Injectable({
  providedIn: 'root',
})
export class AlertService {
  showSuccessAlert: BehaviorSubject<AlertPayload | null>;
  showWarningAlert: BehaviorSubject<AlertPayload | null>;
  showDangerAlert: BehaviorSubject<AlertPayload | null>;

  constructor() {
    this.showSuccessAlert = new BehaviorSubject<AlertPayload | null>(null);
    this.showWarningAlert = new BehaviorSubject<AlertPayload | null>(null);
    this.showDangerAlert = new BehaviorSubject<AlertPayload | null>(null);
  }

  toSentenceCase(value: string): string {
    if (!value) return '';
    return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
  }

  alertSuccess(alertTitle: string, alertSubTitle: string) {
    const payload = {
      title: this.toSentenceCase(alertTitle),
      subTitle: this.toSentenceCase(alertSubTitle),
      alertType: 'success',
    };
    this.showSuccessAlert.next(payload);
  }

  alertWarning(alertTitle: string, alertSubTitle: string) {
    const payload = {
      title: this.toSentenceCase(alertTitle),
      subTitle: this.toSentenceCase(alertSubTitle),
      alertType: 'warning',
    };
    this.showWarningAlert.next(payload);
  }

  alertDanger(alertTitle: string, alertSubTitle: string) {
    const payload = {
      title: this.toSentenceCase(alertTitle),
      subTitle: this.toSentenceCase(alertSubTitle),
      alertType: 'danger',
    };
    this.showDangerAlert.next(payload);
  }
}
