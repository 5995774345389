import { createReducer, on } from '@ngrx/store';
import { IAppState } from 'src/app/common/models/app.model';
import {
  clearCstWalletDetails,
  getCstWalletDetails,
  getCstWalletDetailsFailed,
  getCstWalletDetailsSuccess,
} from './customer.actions';
import { ICstWallet } from '../../models/cst-wallet.model';

const initialState: IAppState<ICstWallet | null> = {
  data: null,
  isLoading: false,
  error: null,
};

export const cstReducer = createReducer(
  initialState,
  on(getCstWalletDetails, (state, { hardRefresh, walletId }) => ({
    ...state,
    data: walletId === state.data?.walletName ? state.data : null,
    isLoading:
      state.data && !hardRefresh && state.data.svWalletId === walletId
        ? false
        : true,
    error: null,
  })),
  on(getCstWalletDetailsSuccess, (state, { data }) => ({
    ...state,
    data,
    isLoading: false,
    error: null,
  })),
  on(getCstWalletDetailsFailed, (state, { error }) => ({
    ...state,
    data: null,
    isLoading: false,
    error,
  })),
  on(clearCstWalletDetails, (state) => ({
    ...state,
    data: null,
    isLoading: false,
    error: null,
  }))
);
