import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  catchError,
  delay,
  filter,
  Observable,
  of,
  retryWhen,
  switchMap,
  throwError,
} from 'rxjs';
import {
  webSocket,
  WebSocketSubject,
  WebSocketSubjectConfig,
} from 'rxjs/webSocket';
import { environment } from 'src/environments/environment';
import {
  IWebSocketMessage,
  IWsConnectionDetails,
  WS_ACTION,
} from '../common/models/web-socket.models';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root',
})
export class WebSocketService {
  private socket$: WebSocketSubject<any>;
  private socketStatus$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  private wsConnectionDetails?: IWsConnectionDetails;
  private retryAttempt: number = 1;

  constructor(private storageService: StorageService) {}

  // Connect to the WebSocket server and return observable
  connect() {
    const socketConfig: WebSocketSubjectConfig<any> = {
      url: environment.webSocketUrl + '/smartvault/sv-websocket-connection',
      protocol: ['Authorization', this.storageService.getSessionToken()],
    };

    // Create a new WebSocket connection
    if (!this.socket$ || this.socket$.closed) {
      this.socket$ = webSocket(socketConfig);
      // Update connection state when connection is opened
      this.socket$
        .pipe(
          retryWhen((errors) =>
            errors.pipe(
              // Track the number of retries
              switchMap((error) => {
                const maxAttempts = 5;
                this.socketStatus$.next(false);
                if (this.retryAttempt >= maxAttempts + 1) {
                  return throwError(
                    () =>
                      new Error(
                        'Max attempts reached for Web Socket reconnection'
                      )
                  );
                }
                // Exponential backoff: delay increases as 2^index (1s, 2s, 4s, 8s, 16s)
                const backoffDelay = Math.pow(2, this.retryAttempt) * 1000; // Exponential delay in ms
                this.retryAttempt++;
                return of(error).pipe(delay(backoffDelay));
              })
            )
          ),
          catchError((error) => {
            this.socketStatus$.next(false);
            console.error('Failed to reconnect the WebSocket: ', error);
            return of({
              action: null,
              sessionId: null,
              webSocketConnectionId: null,
              data: null,
              reqOriginConnectionId: null,
            });
          })
        )
        .subscribe({
          next: (event: IWebSocketMessage) => {
            if (event.action === WS_ACTION.CONNECTION_INIT) {
              // Connection opened, set socketStatus to true
              this.wsConnectionDetails = {
                sessionId: event.sessionId,
                webSocketConnectionId: event.webSocketConnectionId,
              };
              this.retryAttempt = 1;
              this.socketStatus$.next(true);
            }
          },
          error: (error) => {
            // On error, set isConnected to false
            console.error('WebSocket error:', error);
            this.socketStatus$.next(false);
          },
          complete: () => {
            // When the connection is closed, set isConnected to false
            this.socketStatus$.next(false);
          },
        });
    }
  }

  // Send a message to the WebSocket server
  sendMessage(action: WS_ACTION, data: any): void {
    if (this.socket$ && this.socket$.closed === false) {
      this.socket$.next({
        action,
        sessionId: this.wsConnectionDetails.sessionId,
        data,
        reqOriginConnectionId: this.wsConnectionDetails.webSocketConnectionId,
      });
    } else {
      console.error('WebSocket is not open');
    }
  }

  // Observable to get the connection status
  getSocketStatus(): Observable<boolean> {
    return this.socketStatus$.asObservable();
  }

  // Observable to listen the connection
  listenToSocket(): Observable<IWebSocketMessage> {
    return this.socket$
      .asObservable()
      .pipe(
        filter(
          (message: IWebSocketMessage) =>
            message.reqOriginConnectionId ===
            this.wsConnectionDetails.webSocketConnectionId
        )
      );
  }

  // Close the WebSocket connection
  closeConnection(): void {
    if (this.socket$) {
      this.socket$.complete();
      this.socket$ = undefined;
    }
  }
}
