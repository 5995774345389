import { HttpErrorResponse } from '@angular/common/http';
import {
  getWalletGroupBalance,
  getWalletGroupBalanceFailed,
  getWalletGroupBalanceSuccess,
} from './wallet-group-balance.actions';
import { inject } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { mergeMap, map, catchError, of } from 'rxjs';
import { CommonApiService } from 'src/app/services/common-api.service';
import { API } from '../../models/api.enum';
import { IGroupBalanceData } from '../../models/group-balance';

export const groupBalanceEffect = createEffect(
  (actions$ = inject(Actions), apiService = inject(CommonApiService)) => {
    return actions$.pipe(
      ofType(getWalletGroupBalance),
      mergeMap((actions) =>
        apiService
          .get<IGroupBalanceData>(API.GROUP_BALANCE, {
            svCustomerId: actions.svCustomerId,
          })
          .pipe(
            map((data) => getWalletGroupBalanceSuccess({ data })),
            catchError((errorRes: HttpErrorResponse) =>
              of(
                getWalletGroupBalanceFailed({
                  error: errorRes?.error || errorRes,
                })
              )
            )
          )
      )
    );
  },
  { functional: true }
);
