import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandlerFn,
  HttpInterceptorFn,
  HttpRequest,
} from '@angular/common/http';
import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, catchError, throwError } from 'rxjs';
import { clearUserData } from 'src/app/common/auth-feature/store/auth.actions';
import { API } from 'src/app/common/models/api.enum';
import { AlertService } from 'src/app/services/alert.service';
import { StorageService } from 'src/app/services/storage.service';
import { WebSocketService } from 'src/app/services/web-socket.service';

export const errorInterceptor: HttpInterceptorFn = (
  req: HttpRequest<any>,
  next: HttpHandlerFn
): Observable<HttpEvent<any>> => {
  const router = inject(Router);
  const storageService = inject(StorageService);
  const store = inject(Store);
  const alertService = inject(AlertService);
  const wsService = inject(WebSocketService);

  return next(req).pipe(
    catchError((errorRes: HttpErrorResponse) => {
      if (errorRes.status === 401) {
        store.dispatch(clearUserData());
        storageService.clearStorage();
        setTimeout(() => {
          if (!router.url.startsWith('/login')) {
            wsService.closeConnection();
            router.navigate(['/login']);
          }
        }, 500);
      }
      const title =
        !errorRes.status && !navigator.onLine
          ? 'Network Error'
          : errorRes.status === 401
          ? ''
          : `${errorRes.statusText}`; //(${errorRes.status})
      const message =
        !errorRes.status && !navigator.onLine
          ? 'Please connect to the internet'
          : errorRes?.error?.description || '';

      const url = new URL(window.location.href);
      const queryParams = new URLSearchParams(url.search);
      const nsQueryParam = queryParams.get('ns');
      if (nsQueryParam === 'true' && req.url.endsWith(API.VALIDATE)) {
      } else alertService.alertDanger(title, message);

      return throwError(
        () =>
          new Error(
            errorRes?.error?.description ||
              'A network error occurred. Please try again later.'
          )
      );
    })
  );
};
