import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IGroupBalanceState } from '../../models/group-balance';

export const groupBalanceFeatureKey = 'groupBalance';
export const selectGroupBalanceState =
  createFeatureSelector<IGroupBalanceState>(groupBalanceFeatureKey);
export const selectGroupBalanceData = createSelector(
  selectGroupBalanceState,
  (state) => state.data
);

export const selectGroupBalanceisLoading = createSelector(
  selectGroupBalanceState,
  (state) => state.isLoading
);

export const selectGroupBalanceError = createSelector(
  selectGroupBalanceState,
  (state) => state.error
);
