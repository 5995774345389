import { ActionReducer } from '@ngrx/store';
import { logoutUserSuccess } from '../auth-feature/store/auth.actions';

export function clearStoresMetaReducer(
  reducer: ActionReducer<any>
): ActionReducer<any> {
  return (state, action) => {
    if (action.type === logoutUserSuccess.type) state = {};
    return reducer(state, action);
  };
}
