import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IProfileDetailState } from 'src/app/common/models/profiles.model';

export const myProfileDetailFeatureKey = 'myProfileDetail';
export const selectMyProfileDetailState =
  createFeatureSelector<IProfileDetailState>(myProfileDetailFeatureKey);
export const selectMyProfileDetailData = createSelector(
  selectMyProfileDetailState,
  (state) => state.data
);

export const selectMyProfileDetailisLoading = createSelector(
  selectMyProfileDetailState,
  (state) => state.isLoading
);

export const selectMyProfileDetailError = createSelector(
  selectMyProfileDetailState,
  (state) => state.error
);
