import { inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { WebSocketService } from 'src/app/services/web-socket.service';
import {
  wsFetchBulkBalance,
  wsFetchBulkBalanceSuccess,
} from './ws-bulk-bal-enq.actions';
import { catchError, filter, first, map, of, switchMap } from 'rxjs';
import { WS_ACTION } from '../../models/web-socket.models';
import { IWsBulkBalanceRes } from './ws-bulk-bal-enq.models';

export const wsBulkBalEnqEffect = createEffect(
  (actions$ = inject(Actions), wsService = inject(WebSocketService)) =>
    actions$.pipe(
      ofType(wsFetchBulkBalance),
      switchMap(({ payload }) =>
        wsService.getSocketStatus().pipe(
          filter(Boolean),
          first(),
          switchMap(() => {
            wsService.sendMessage(
              WS_ACTION.BULK_WALLET_BALANCE_ENQUIRY,
              payload
            );
            return wsService.listenToSocket().pipe(
              filter(
                (res) => res.action === WS_ACTION.BULK_WALLET_BALANCE_ENQUIRY
              ),
              map((res) =>
                wsFetchBulkBalanceSuccess({
                  data: res.data as IWsBulkBalanceRes,
                })
              ),
              catchError(() => of(wsFetchBulkBalanceSuccess({ data: null })))
            );
          })
        )
      )
    ),
  { functional: true }
);
