import { createReducer, on } from '@ngrx/store';
import { IProfileDetailState } from 'src/app/common/models/profiles.model';
import {
  clearMyProfileDetail,
  myProfileDetail,
  myProfileDetailFailed,
  myProfileDetailSuccess,
} from './my-profile.actions';

const initialData: IProfileDetailState = {
  data: null,
  isLoading: false,
  error: null,
};

export const myProfileDetailReducers = createReducer(
  initialData,
  on(myProfileDetail, (state) => ({
    ...state,
    data: state.data,
    isLoading: state.data ? false : true,
    error: null,
  })),
  on(myProfileDetailSuccess, (state, { data }) => ({
    ...state,
    data,
    isLoading: false,
    error: null,
  })),
  on(myProfileDetailFailed, (state, { error }) => ({
    ...state,
    data: null,
    isLoading: false,
    error,
  })),
  on(clearMyProfileDetail, (state) => initialData)
);
