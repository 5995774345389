import { createReducer, on } from '@ngrx/store';
import { IGroupBalanceState } from '../../models/group-balance';
import {
  clearWalletGroupBalance,
  getWalletGroupBalance,
  getWalletGroupBalanceFailed,
  getWalletGroupBalanceSuccess,
} from './wallet-group-balance.actions';

const initialState: IGroupBalanceState = {
  data: null,
  error: null,
  isLoading: false,
};

export const groupBalanceReducers = createReducer(
  initialState,
  on(getWalletGroupBalance, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  })),

  on(getWalletGroupBalanceSuccess, (state, { data }) => ({
    ...state,
    data,
    isLoading: false,
    error: null,
  })),

  on(getWalletGroupBalanceFailed, (state, { error }) => ({
    ...state,
    data: null,
    isLoading: false,
    error,
  })),
  on(clearWalletGroupBalance, (state) => initialState)
);
