import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IAppState } from 'src/app/common/models/app.model';
import { ICstWallet } from '../../models/cst-wallet.model';

export const customerFeatureKey = 'customer';
export const selectCstState =
  createFeatureSelector<IAppState<ICstWallet | null>>(customerFeatureKey);
export const selectCstWalletData = createSelector(
  selectCstState,
  (state: IAppState<ICstWallet | null>) => state.data
);
export const selectCstIsLoading = createSelector(
  selectCstState,
  (state: IAppState<ICstWallet | null>) => state.isLoading
);
export const selectCstError = createSelector(
  selectCstState,
  (state: IAppState<ICstWallet | null>) => state.error
);
