import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IWsBbeState } from './ws-bulk-bal-enq.models';

export const wsBulkBalEnqFeatureKey = 'wsBulkBalEnq';

const selectWsBbeState = createFeatureSelector<IWsBbeState>(
  wsBulkBalEnqFeatureKey
);

export const selectWsBbeData = createSelector(
  selectWsBbeState,
  (state) => state.data
);

export const selectWsBbeIsLoading = createSelector(
  selectWsBbeState,
  (state) => state.isLoading
);
