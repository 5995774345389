import { createAction, props } from '@ngrx/store';
import { IErrorRes } from 'src/app/common/models/app.model';

export const myProfileDetail = createAction(
  '[MY PROFILE DETAILS PAGE] My Profile Details',
  props<{ profileId: string }>()
);

export const myProfileDetailSuccess = createAction(
  '[MY PROFILE DETAILS API] My Profile Details Success',
  props<{ data: any }>()
);

export const myProfileDetailFailed = createAction(
  '[MY PROFILE DETAILS API] My Profile Details Failed',
  props<{ error: IErrorRes }>()
);

export const clearMyProfileDetail = createAction(
  '[MY PROFILE DETAILS API] Clear My Profile Details'
);
