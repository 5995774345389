import { createReducer, on } from '@ngrx/store';
import {
  clearCstWalletList,
  getCstWalletList,
  getCstWalletListFailed,
  getCstWalletListSuccess,
} from './cst-wallet-list.actions';
import { ICstWalletListApiRes } from '../../models/cst-wallet.model';
import { IAppState } from '../../models/app.model';

const initialState: IAppState<ICstWalletListApiRes | null> = {
  data: null,
  isLoading: false,
  error: null,
};

export const cstWalletListReducer = createReducer(
  initialState,
  on(getCstWalletList, (state, { offset }) => ({
    ...state,
    data: offset ? state.data : null,
    isLoading: true,
    error: null,
  })),
  on(getCstWalletListSuccess, (state, { data }) => ({
    ...state,
    data: data?.offset
      ? { ...data, wallets: [...state.data.wallets, ...data.wallets] }
      : data,
    isLoading: false,
    error: null,
  })),
  on(getCstWalletListFailed, (state, { error }) => ({
    ...state,
    data: null,
    isLoading: false,
    error,
  })),
  on(clearCstWalletList, () => ({ ...initialState }))
);
