export enum API {
  USERS = '',
  LOGIN = 'iam/realms/account/login',
  LOGOUT = 'iam/realms/account/logout',
  VALIDATE = 'iam/realms/account/token/validate',
  UPDATE_PASSWORD = 'smartvault/users/update/',
  FORGET_PASSWORD = 'smartvault/verifyUser/USER_EMAIL',
  VERIFY_CHANGE_PASSWORD_SESSION = 'smartvault/reset/session/SESSION_ID',
  CHANGE_PASSWORD = 'smartvault/reset/password/',
  SEND_OTP = 'smartvault/otp/PROFILE_ID/',
  VERIFY_OTP = 'smartvault/otp/verify/PROFILE_ID/',
  CST_SERVICE_API = 'smartvault/service-accounts/SV_CUSTMOR_ID/WALLET_NAME/',
  CST_CC_SERVICE_API = 'smartvault/service-accounts/SV_CUSTMOR_ID',
  CSTACCOUNTLICENCE = 'smartvault/licence/',
  CST_CC_TEAM_MANAGEMENT_LISTING = 'smartvault/users/',
  CST_CC_ROLE = 'smartvault/roles/',
  SERVICE_NODE = 'smartvault/servicenode/roles/',
  CST_CC_ORG_INFORMATION = 'smartvault/customers/',
  CST_WALLETS_API = 'smartvault/api/v1/wallets/',
  WALLETS = 'smartvault/api/v1/wallets/SV_CUSTOMER_ID/WALLET_ID',
  ACTIVE_SERVICES_API = 'smartvault/service-accounts/getActiveServices',
  CST_ACCOUNT_LICENCE_BSP_UPDATE_CREDS = 'api/v1/SV_CUSTOMER_ID/SV_CUSTOMER_NAME/credentials-update',
  CST_ACCOUNT_LICENCE_BSP_FETCH_CREDS = 'api/v1/SV_CUSTOMER_ID/SV_CUSTOMER_NAME/fetch-credentials',
  // CST_RATE_CARD_LIST_API = 'smartvault/admin/SV_CUSTOMER_ID/WALLET_ID/rate-cards/audit',
  CST_RATE_CARD_LIST_API = 'smartvault/rate-cards/SV_CUSTOMER_ID/WALLET_ID/audit/', // New URL of rate-card-list API
  CST_ANALYTICS_API = 'smartvault/analytics/SERVICE_TYPE/DETAIL_TYPE/summary',

  // Super Admin Routes
  CUSTOMERS_ORGS = 'smartvault/customers/',
  WALLET_OPERATIONS = 'smartvault/api/v1/wallets/SV_CUSTOMER_ID/WALLET_ID/recharge',
  WALLET_AUDIT = 'smartvault/api/v1/wallet-audits/',
  SSO_WITH_ROLES = 'iam/realms/account/sa/login',
  GROUP_BALANCE = 'smartvault/api/v1/wallets/balance-enquiry/aggregate-balance-sum',
  UPDATE_LICENSE = 'smartvault/licence/SV_LICENSE_ID',
  UPDATE_RATE_CARD = 'smartvault/rate-cards/SV_CUSTOMER_ID/WALLET_ID/',

  // Reseller APIs
  CREATE_L2_CUSTOMER = 'smartvault/customers/',
  CREATE_L2_WALLET = 'smartvault/api/v1/wallets/',
  GET_ACTIVE_RATE_CARD = 'smartvault/rate-cards/SV_CUSTOMER_ID/WALLET_ID/',
  CREATE_L2_SERVICE_ACCOUNT = 'smartvault/service-accounts/SV_CUSTOMER_ID/WALLET_ID/',
  GET_ACTIVE_LICENSE = 'smartvault/licence/SV_LICENSE_ID',
}
