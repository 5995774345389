import { createAction, props } from '@ngrx/store';
import {
  IWsBulkBalancePayload,
  IWsBulkBalanceRes,
} from './ws-bulk-bal-enq.models';

export const wsFetchBulkBalance = createAction(
  '[WS FETCH BULK BALANCE REQ] fetch bulk balance',
  props<{ payload: IWsBulkBalancePayload[] }>()
);

export const wsFetchBulkBalanceSuccess = createAction(
  '[WS FETCH BULK BALANCE RES] fetch bulk balance success',
  props<{ data: IWsBulkBalanceRes }>()
);

export const clearBulkBalanceStore = createAction('[CLEAR BULK BALANCE STORE]');
