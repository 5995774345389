import { createAction, props } from '@ngrx/store';
import { IErrorRes } from 'src/app/common/models/app.model';
import { IGroupBalanceData } from '../../models/group-balance';

export const getWalletGroupBalance = createAction(
  '[GROUP BALANCE API] Group Balance',
  props<{ svCustomerId?: string }>()
);

export const getWalletGroupBalanceSuccess = createAction(
  '[GROUP BALANCE API] Group Balance Success',
  props<{ data: IGroupBalanceData }>()
);

export const getWalletGroupBalanceFailed = createAction(
  '[GROUP BALANCE API] Group Balance Failed',
  props<{ error: IErrorRes }>()
);

export const clearWalletGroupBalance = createAction(
  '[CLEAR GROUP BALANCE STORE] Clear Group Balance'
);
